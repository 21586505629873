
<template>
  <div class="reserve-form-wrap">
  
    <!------------------------ 현장방문입장  -------------------------------->
    <!-- 예약입력  -->
    <div class="reserve-form-wrap--info" v-show="MODE == 1 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--top">
        <p class="reserve-form-wrap--title"><strong>휴대전화 번호</strong>를<br>입력해주세요.</p>
        <p class="reserve-form-wrap--text">
          방문예약하신 고객님은<br />
          <strong>예약하신 휴대전화번호로 방문등록</strong>을 하고<br />
          안내에 따라 입장하여 주시기 바랍니다.
        </p>
      </div>
      <div class="reserve-form-wrap--sub">
        <div class="reserve-form-wrap--sub-kakao">
          방문등록하고 <strong>카카오톡</strong>으로 확인하세요
        </div>
      </div>
    </div>

    <!-- 예약입력 대기상태 -->
    <div class="reserve-form-wrap--info type-wait" v-show="MODE == 2 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--top">
        <span class="reserve-form-wrap--title">현재대기</span>
        <strong class="reserve-form-wrap--wait">{{ WAIT_NUM }} <span class="reserve-form-wrap--wait-unit">팀</span></strong>
        <p class="reserve-form-wrap--text">
          상담을 원하시는 고객님께서는<br>
          <strong>휴대전화 번호로 상담시청</strong>을 해주시기 바랍니다.
        </p>
      </div>
      <div class="reserve-form-wrap--sub">
        <div class="reserve-form-wrap--sub-kakao">
          상담석이 지정되면<br>
          <strong>카카오 알림톡</strong>으로 알려드리며,<br>
          현재 대기상황 및 예상 대기시간을 확인 할 수 있습니다.
        </div>
      </div>
    </div>

    <!-- 공통 전화번호 입력 -->
    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 1 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--keypad-in">
        <div class="reserve-form-wrap--keypad-top">
          <!--span class="reserve-form-wrap--keypad-prefix">010-</span-->
          <input type="text" readonly :value="PHONE_NUMBER">
        </div>
        <v-checkbox class="reserve-form-wrap--keypad-chk-label" :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'"
          v-model="IS_AGREE" label="서비스 이용약관과 개인정보 처리방식에 동의합니다."></v-checkbox>
      </div>
      <div class="reserve-form-wrap--keypad-pad">
        <div><button type="button" @click="fnClickNumber(1)">1</button></div>
        <div><button type="button" @click="fnClickNumber(2)">2</button></div>
        <div><button type="button" @click="fnClickNumber(3)">3</button></div>
        <div><button type="button" @click="fnClickNumber(4)">4</button></div>
        <div><button type="button" @click="fnClickNumber(5)">5</button></div>
        <div><button type="button" @click="fnClickNumber(6)">6</button></div>
        <div><button type="button" @click="fnClickNumber(7)">7</button></div>
        <div><button type="button" @click="fnClickNumber(8)">8</button></div>
        <div><button type="button" @click="fnClickNumber(9)">9</button></div>
        <div><button type="button" class="btn-keypad-del" @click="fnDeleteNumber"><span class="hide">del</span></button>
        </div>
        <div><button type="button" @click="fnClickNumber(0)">0</button></div>
        <div><button type="button" class="btn-keypad-in" @click="getListData">입력</button></div>
      </div>
    </div>

    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 2 && !MOBI_YN && !T_BOOL">
      <div class="reserve-form-wrap--keypad-in" style="text-align:center;line-height: 1.2;">
        {{ CUST_NAME }}<br />
        <span style="font-size:50px;">방문예약 정보를 확인했습니다.<br />
          고객님의 방문을 환영합니다.<br /><br /></span>
        <span style="font-size:30px;">아래확인버튼을 누르고 방문등록을 완료해 주세요.<br />
          모바일로 다양한 분양정보를 받으실 수 있습니다.<br />
          안내에 따라 입장하여 주시기 바랍니다.</span>
      </div>
      <div class="reserve-form-wrap--keypad-pad" style="height: 30% !important;">
        <div><button type="button" class="btn-keypad-del" @click="fnBackView"><span class="hide">del</span></button>
        </div>
        <div><button type="button" class="btn-keypad-in" @click="fnNumberEnd">확인</button></div>
      </div>
    </div>

    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 1 && MOBI_YN && T_BOOL">

      <div class="reserve-form-wrap--info">
        <div class="reserve-form-wrap--sub">
          <div class="reserve-form-wrap--sub-kakao">
            방문등록하고 <strong>카카오톡</strong>으로 확인하세요
          </div>
        </div>
        <div class="reserve-form-wrap--top">
          <p class="reserve-form-wrap--text">
            방문예약하신 고객님은<br />
            <strong>예약하신 휴대전화번호로 방문등록</strong>을 하고<br />
            안내에 따라 입장하여 주시기 바랍니다.
          </p>
        </div>
      </div>

      <div class="reserve-form-wrap--keypad-in">
        <div class="reserve-form-wrap--keypad-top">
          <input type="text" readonly :value="PHONE_NUMBER">
        </div>
        <v-checkbox class="reserve-form-wrap--keypad-chk-label" :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'"
          v-model="IS_AGREE" label="서비스 이용약관과 개인정보 처리방식에 동의합니다."></v-checkbox>
      </div>

      <div class="reserve-form-wrap--keypad-pad">
        <div><button type="button" @click="fnClickNumber(1)">1</button></div>
        <div><button type="button" @click="fnClickNumber(2)">2</button></div>
        <div><button type="button" @click="fnClickNumber(3)">3</button></div>
        <div><button type="button" @click="fnClickNumber(4)">4</button></div>
        <div><button type="button" @click="fnClickNumber(5)">5</button></div>
        <div><button type="button" @click="fnClickNumber(6)">6</button></div>
        <div><button type="button" @click="fnClickNumber(7)">7</button></div>
        <div><button type="button" @click="fnClickNumber(8)">8</button></div>
        <div><button type="button" @click="fnClickNumber(9)">9</button></div>
        <div><button type="button" class="btn-keypad-del" @click="fnDeleteNumber"><span class="hide">del</span></button>
        </div>
        <div><button type="button" @click="fnClickNumber(0)">0</button></div>
        <div><button type="button" class="btn-keypad-in" @click="getListData">입력</button></div>
      </div>
    </div>

    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 2 && MOBI_YN && T_BOOL">

      <div class="reserve-form-wrap--info">
        <div class="reserve-form-wrap--sub">
          <div class="reserve-form-wrap--sub-kakao">
            방문등록하고 <strong>카카오톡</strong>으로 확인하세요
          </div>
        </div>
        <div class="reserve-form-wrap--top">
          <p class="reserve-form-wrap--text">
            방문예약하신 고객님은<br />
            <strong>예약하신 휴대전화번호로 방문등록</strong>을 하고<br />
            안내에 따라 입장하여 주시기 바랍니다.
          </p>
        </div>
      </div>

      <div class="reserve-form-wrap--keypad-in" style="text-align:center; line-height: 1.2;">
        {{ CUST_NAME }}<br />
        <span style="font-size:50px;">방문예약 정보를 확인했습니다.<br />
          고객님의 방문을 환영합니다.<br /><br /></span>
        <span style="font-size:30px;">아래확인버튼을 누르고 방문등록을 완료해 주세요.<br />
          모바일로 다양한 분양정보를 받으실 수 있습니다.<br />
          안내에 따라 입장하여 주시기 바랍니다.</span>
      </div>
      <div class="reserve-form-wrap--keypad-pad" style="height: 30% !important;">
        <div><button type="button" class="btn-keypad-del" @click="fnBackView"><span class="hide">del</span></button>
        </div>
        <div><button type="button" class="btn-keypad-in" @click="fnNumberEnd">확인</button></div>
      </div>
    </div>

  </div>
</template>
  
<script>
  import axios from "axios"

  export default {
    name: 'MENU_CAP0530_co',
    components: {},
    metaInfo() {
      return {
        title: "현장방문입장",
      }
    },

    data() {
      return {
        MODE: 1, //예약입력 모드 {1: 기본, 2:대기상태}
        RESULT_MODE: 1,  //결과확인 모드 {1:입력, 2:처리결과}
        WAIT_NUM: 0, //대기팀
        PHONE_NUMBER: '010-', //키입력
        IS_AGREE: true,
        BUSS_CODE: this.$route.query.buss_code.replace(/[\"\']/gi, ''),
        CMP_ID: this.$route.query.cmp_id.replace(/[\"\']/gi, ''),
        CUST_NAME:'',

        ACCESS_TOKEN : "",    //Arthur.Kim_20220924 알림톡토큰
        EXPIRES_IN   : "",     //Arthur.Kim_20220924 알림톡토큰유효시간

        MOBI_YN : true,  //모바일 여부

        T_YN: '',
        T_BOOL: false, //테스트
      }
    },

    methods: {

      //**********************************
      //      사전고객 조회 호출
      //**********************************
      async getListData() {
        
        if (!this.fnNumberProc()) {
          return false;
        }
        
        let requestData = {
          headers: {},
          sendData: {},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/work/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.work.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["CMP_ID"] = this.CMP_ID;              //캠페인 아이디
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
        requestData.sendData["RES_DT_YN"] = 'Y';
        requestData.sendData["CUST_PHONE"] = this.PHONE_NUMBER.replaceAll("-", "");       //핸드폰 번호

        let response = await this.common_postCall(requestData);

        if (response.HEADER.ERROR_FLAG) {
          this.RESULT_MODE = 1;

          let msg = '관리자에게 문의해주세요.';
          if(!this.mixin_isEmpty(response.HEADER.MSG)) {
            msg = response.HEADER.MSG;
          }
          this.common_alert(msg, "error");
          return;
        } else {
          let data = response.DATA;

          //데이터가 존재하면 
          if (data.length > 0) {
            //공통코드 (VIST_GUB_CD) ->CNSL_STAT_CD_01(미방문),CNSL_STAT_CD_02(방문완료)
            if(data[0].VIST_GUB_CD == "CNSL_VISIT_CD_02") {
              this.RESULT_MODE = 1;

              this.common_alert("이미 방문 인증 되었습니다.", "chk");
              return;
            } else if (data[0].VIST_GUB_CD == "CNSL_VISIT_CD_01") {
              //인증되었습니다
              this.setVisitData();
            }

          } else {
            this.RESULT_MODE = 1;

            this.common_alert("사전예약이 아닌 고객님은\n미사전예약 현장방문을 이용해주세요.", "chk");
            return;
          }
        }
      },

      //**********************************
      // 사전예약 방문 고객 타입 변경
      //**********************************
      async setVisitData() {

        let requestData = {
          headers: {},
          sendData: {},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/work/manage/update";
        requestData.headers["SERVICE"] = "hlw.campaign.work.manage";
        requestData.headers["METHOD"] = "update";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["CMP_ID"] = this.CMP_ID;              //캠페인 아이디
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
        requestData.sendData["CUST_PHONE"] = this.PHONE_NUMBER.replaceAll("-", "");       //핸드폰 번호
        requestData.sendData["VIST_GUB_CD"] = "CNSL_VISIT_CD_02";        //방문완료

        requestData.sendData["UPD_ID"] = this.user_id;

        let response = await this.common_postCall(requestData);
        let resDatas=response.DATA;

        if (response.HEADER.ERROR_FLAG) {
          this.RESULT_MODE = 1;

          this.common_alert("관리자에게 문의해주세요.", "error");
          return;
        } else {
          this.RESULT_MODE = 2;

          if(!this.mixin_isEmpty(resDatas)) {
            resDatas=resDatas[0]
            if(this.mixin_isEmpty(resDatas.CUST_NAME)) {
              this.CUST_NAME=this.PHONE_NUMBER.substring(0, this.PHONE_NUMBER.length-4)+'****님';
            }else{
              this.CUST_NAME=resDatas.CUST_NAME.substring(0, resDatas.CUST_NAME.length-1)+'*님';
            }
          }

          //알림톡 발송
          this.sendMessage();
        }
      },

      fnClickNumber(clickNumber) {
        if (this.PHONE_NUMBER == undefined) {
          this.PHONE_NUMBER = clickNumber;
        } else {
          if (this.PHONE_NUMBER.length < 13) {
            this.PHONE_NUMBER = this.fnNumberFomat(this.PHONE_NUMBER + clickNumber);
          }
        }
      },

      async sendMessage() {

        // this.mixin_alarmTalk_token();
        let requestData = {
          headers: {},
          sendData: {},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/alarmTalk-msgformat/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.alarmTalk-msgformat.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        // requestData.sendData["CUST_PHONE"] = this.PHONE_NUMBER.replaceAll("-", "");
        // requestData.sendData["CUST_USER_KEY"] = this.CUST_USER_KEY; 

        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
        requestData.sendData["CMP_ID"] = this.CMP_ID;                   //캠페인 아이디
        requestData.sendData["MSG_TMPL_TYPE"] = "PLACE_VISIT_NOTI";           //메세지 속성 코드(신청완료)    
        
        let response = await this.common_postCall(requestData);

        if (response.HEADER.ERROR_FLAG) {
          this.common_alert("관리자에게 문의해주세요.", "error");
          return;
        } else {
          let data = response.DATA;
          let data_btn = response.DATA_BTN;
          
          if(data.length > 0){
            
            let msgFormat =data[0].MSG_CONT;  //메세지내용
            msgFormat = msgFormat.replace(/#{사업지명}/g, data[0].CMP_NAME).replace(/#{고객명}/g,"");
            
            // let msgFormatDecode = this.decode(msgFormat);  
            let setButtons = [];
            
            if(data_btn.length > 0){
              let i = 0;
              let dataBtnLen = typeof data_btn != undefined &&  typeof data_btn != "undefined" ? data_btn.length : 0;   

              for(; i < dataBtnLen; i++){
                setButtons.push({
                  name: data_btn[i].BTN_NAME,
                  type: data_btn[i].BTN_LINK_TYPE,
                  url_mobile: data_btn[i].BTN_LINK_URL
                });
              }
            }
                
            const sendButton = { "button": setButtons };

            let requestAlarmTalkData = {
              headers: {},
              sendData: {},
            };

            let buttonToString = `'${JSON.stringify(sendButton)}'`;

            requestAlarmTalkData.headers["URL"] = "/api/hlw/campaign/alarmTalk-postmsg/manage/post";
            requestAlarmTalkData.headers["SERVICE"] = "hlw.campaign.alarmTalk-postmsg.manage";
            requestAlarmTalkData.headers["METHOD"] = "post";
            requestAlarmTalkData.headers["ASYNC"] = false;

            requestAlarmTalkData.sendData["CMP_ID"] = this.CMP_ID;
            requestAlarmTalkData.sendData["TRAN_SENDER_KEY"] = data[0].SNDR_KEY;
            requestAlarmTalkData.sendData["TRAN_TMPL_CD"] = data[0].MSG_TMPL_CODE;
            requestAlarmTalkData.sendData["TRAN_PHONE"] = this.CUST_PHONE.replaceAll("-", "");;
            requestAlarmTalkData.sendData["TRAN_CALLBACK"] = data[0].SNDR_PHONE.replaceAll("-", "");
            requestAlarmTalkData.sendData["TRAN_MSG"] = msgFormat;
            requestAlarmTalkData.sendData["TRAN_REPLACE_MSG"] = msgFormat;
            if(sendButton["button"].length != 0) requestAlarmTalkData.sendData["TRAN_BUTTON"] = this.encode(buttonToString);

            let alarmTalkResponse = await this.common_postCall(requestAlarmTalkData);
            if (alarmTalkResponse.HEADER.ERROR_FLAG) {
              this.common_alert("관리자에게 문의해주세요.", "error");
            }
          }
        }
      },

      decode(strText) {
        //값존재유무 체크
        if (strText === "" || strText == null) {
          return strText;
        }
        strText = strText.toString();

        //문자열 길이가 4이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }
        //순서바뀌면 안나옴
        strText = strText.replaceAll("&lt;", "<");
        strText = strText.replaceAll("&gt;", ">");
        strText = strText.replaceAll("&amp;", "&");
        strText = strText.replaceAll("&quot;", '"');
        strText = strText.replaceAll("&apos;", "'");
        strText = strText.replaceAll("<br>", "\r");
        strText = strText.replaceAll("<p>", "\n");
        strText = strText.replaceAll("&#91;", "[");
        strText = strText.replaceAll("&#93;", "]");
        strText = strText.replaceAll("&#40;", "(");
        strText = strText.replaceAll("&#41;", ")");

        return strText;
      },
      encode(strText) {
        // 값 존재 유무 체크
        if (strText === "" || strText == null) {
          return strText;
        }

        strText = strText.toString();

        // 문자열 길이가 4 이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }

        // 문자열을 HTML 엔티티로 인코딩
        strText = strText.replaceAll("&", "&amp;");
        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");
        strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<p>");
        strText = strText.replaceAll("\[", "&#91;");
        strText = strText.replaceAll("\]", "&#93;");
        strText = strText.replaceAll("\(", "&#40;");
        strText = strText.replaceAll("\)", "&#41;");

        return strText;
      },

      fnDeleteNumber() {
        if (this.PHONE_NUMBER != undefined && this.PHONE_NUMBER != '') {
          var makePhoneNumber = this.PHONE_NUMBER.replaceAll('-', '');
          makePhoneNumber = makePhoneNumber.substring(0, makePhoneNumber.length - 1);
          this.PHONE_NUMBER = this.fnNumberFomat(makePhoneNumber);
        }
      },

      fnNumberProc() {
        if (!this.IS_AGREE) {
          this.common_alert("이용약관 동의후에 방문등록해 주세요.", "chk");
          return false;
        }

        if (this.PHONE_NUMBER.length < 13) {
          this.common_alert("휴대전화번호를 정확히 입력해 주세요.", 'chk');
          return false;
        }

        return true;
      },

      fnNumberFomat(phoneNumber) {
        var makePhoneNumber = "";
        if (phoneNumber != undefined && phoneNumber != '') {
          makePhoneNumber = phoneNumber.replaceAll('-', '');
          if (makePhoneNumber.length > 9) {
            makePhoneNumber = this.mixin_setPhoneNo(makePhoneNumber);
          } else {
            if (makePhoneNumber.length === 3) {
              makePhoneNumber = makePhoneNumber + '-';
            } else if (makePhoneNumber.length === 7) {
              makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3) + '-';
            } else if (makePhoneNumber.length > 7) {
              makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3, 7) + '-' + makePhoneNumber.substring(7);
            } else if (makePhoneNumber.length > 3) {
              makePhoneNumber = makePhoneNumber.substring(0, 3) + '-' + makePhoneNumber.substring(3);
            }
          }
        }
        return makePhoneNumber;
      },

      fnBackView() {
        this.RESULT_MODE = 1;
      },

      fnNumberEnd() {
        this.RESULT_MODE = 1;
        this.IS_AGREE = true;
        this.PHONE_NUMBER = '010-';

        this.$emit("reload");
      },

    },

    mounted() {
      this.ACCESS_TOKEN = this.$store.getters['authStore/GE_ACCESS_TOKEN'];
      this.EXPIRES_IN = this.$store.getters['authStore/GE_EXPIRES_IN'];

      //모바일 여부
      this.MOBI_YN = this.mixin_mobi_yn();
      if(this.MOBI_YN) { 
        $('head').append('<meta name="viewport" content="width=device-width, initial-scale=0.7, maximum-scale=0.7, user-scalable=0">');
      }

      if(!this.mixin_isEmpty(this.$route.query.t)) {
        this.T_YN = this.$route.query.t.replace(/[\"\']/gi, '');
        if(this.T_YN == 'Y') {
          this.T_BOOL = true;
        }
      }
    },

    computed: {
      initHeaders() {
        return {
        };
      },
      paramData() {

        return {
        };
      },
    },

  };
</script>
  
<style>
  .reserve-form-wrap {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
  }
</style>